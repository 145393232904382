import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { toast, useBloc } from "src/state/state";
import ErrorBox from "src/ui/components/StyledComponents/ErrorBox";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import type Path from "src/constants/path";
import { extractErrorCode } from "src/lib/errors";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import Link from "src/ui/components/Link/Link";
import Loader from "src/ui/components/Loader/Loader";
import SignupWrapper from "src/ui/components/SignupWrapper/SignupWrapper";
import Translate from "src/ui/components/Translate/Translate";
import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import { z } from "zod";
import { CheckmarkIcon } from "src/constants/icons";

interface Props {
  onSuccess?: () => unknown;
  loginLink: Path | (() => unknown);
  noFrame?: boolean;
}

const SchemaEmail = z.object({
  email: z.string().email()
});

const SchemaVerification = z.object({
  newPassword: z
    .string({
      required_error: translate("error_validation_required")
    })
    .min(12, { message: "" }),
  code: z.coerce
    .string({ required_error: translate("error_validation_required") })
    .regex(/\d+/, translate("error.invalid_code"))
    .length(6, { message: translate("error.invalid_code") })
});

const ResetPasswordForm: FC<Props> = ({
  loginLink,
  noFrame = false,
  onSuccess
}) => {
  const [, { resetPassword, resetPasswordVerify }] =
    useBloc(AuthenticationBloc);
  const [resetToken, setResetToken] = useState<string>("");
  const [resetEmail, setResetEmail] = useState<string>("");
  const [error, setError] = useState<string | undefined>();
  const [showResendLink, setShowResendLink] = useState<boolean>(false);
  const [showCheckmarkIcon, setShowCheckmarkIcon] = React.useState(false);

  function handleInputChange(value: string) {
    if (value.length >= 12) {
      setShowCheckmarkIcon(true);
    } else {
      setShowCheckmarkIcon(false);
    }
  }

  useEffect(() => {
    if (!showResendLink) {
      const to = setTimeout(() => {
        setShowResendLink(true);
      }, 10000);

      return () => clearTimeout(to);
    }
  }, [showResendLink]);

  const handlePasswordResetRequest = async ({
    email
  }: {
    email: string;
  }): Promise<void> => {
    try {
      const resetResponse = await resetPassword({ email });
      setResetEmail(email);
      setResetToken(resetResponse.verificationToken);
    } catch (e) {
      reportErrorSentry(e);
    }
  };

  const handlePasswordReset = async ({
    newPassword,
    code
  }: {
    newPassword: string;
    code: string;
  }): Promise<void> => {
    setError(undefined);
    try {
      await resetPasswordVerify({
        newPassword,
        verificationToken: resetToken,
        verificationCode: code,
        email: resetEmail
      });

      toast.success("success_password_reset");
      onSuccess?.();
    } catch (e: unknown) {
      setError(
        (e as { body?: { code?: string } }).body?.code ?? extractErrorCode(e)
      );
    }
  };

  const handleResendCode = (): void => {
    if (resetEmail) {
      void resetPassword({ email: resetEmail });
      setShowResendLink(false);
    }
  };

  const Frame = noFrame ? "div" : SignupWrapper;

  const loginLinkProps = {
    to: typeof loginLink === "function" ? "#" : loginLink,
    onClick: typeof loginLink === "function" ? loginLink : undefined
  };

  const resendCodeLinkProps = {
    to: "#",
    onClick: handleResendCode
  };

  return (
    <Frame loadingKey={LoadingKey.none} hideBottomNavbar>
      {/* First step: "request" */}
      {!resetToken && (
        <Loader>
          <nine-heading
            style={{
              "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
            }}
          >
            <h3 className="as-h4-large">
              <Translate msg="password.reset" />
            </h3>
            <nine-spacer s="md"></nine-spacer>
            <p className="m0 color-c-80">
              <Translate msg="email.enterInfo" />
            </p>
          </nine-heading>

          <nine-spacer s="xl"></nine-spacer>
          <div
            style={{
              textAlign: "left",
              margin: "auto",
              maxWidth: `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
            }}
          >
            <AutoForm
              schema={SchemaEmail}
              onSubmit={(data) => void handlePasswordResetRequest(data)}
              onChange={() => setError(undefined)}
            >
              <AutoFormInputField
                name="email"
                label={translate("emailAddress")}
                type="email"
                autoComplete="username"
                data-testid="email"
                isRequired={true}
              />

              <div>
                <nine-spacer s="md"></nine-spacer>
                <Link
                  className="color-c-80"
                  style={{ fontWeight: 400 }}
                  {...loginLinkProps}
                >
                  <Translate msg="button.backToLogin" />
                </Link>
              </div>

              <nine-content>
                <div className="center">
                  <nine-spacer s="xl"></nine-spacer>
                  <Button type="submit" style={{ margin: "auto" }}>
                    <Translate msg="continue" />
                  </Button>
                </div>
              </nine-content>
            </AutoForm>
          </div>
        </Loader>
      )}

      {/* Second step: "verify" */}
      {resetToken && (
        <Loader>
          <nine-heading
            style={{
              "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
            }}
          >
            <h3 className="as-h4-large">{translate("password.reset")}</h3>
            <nine-spacer s="md"></nine-spacer>
            <p className="m0 color-c-80">
              <Translate msg="verification.code" />{" "}
              <strong>{resetEmail}</strong>
            </p>
          </nine-heading>

          <nine-spacer s="xl"></nine-spacer>

          <div
            style={{
              margin: "auto",
              textAlign: "left",
              maxWidth: `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
            }}
          >
            {" "}
            <AutoForm
              schema={SchemaVerification}
              onSubmit={(data) => {
                void handlePasswordReset(data);
              }}
            >
              <AutoFormInputField
                name="code"
                label={translate("verificationCode")}
                required={true}
                mask="000000"
              />
              <nine-spacer s="sm"></nine-spacer>
              <AutoFormInputField
                isPasswordToggleEnabled
                name="newPassword"
                label={translate("password.new")}
                type="password"
                required={true}
                onChange={(value: string) => handleInputChange(value)}
                description={`${!showCheckmarkIcon ? "• " : ""} ${translate("invalid_password")}`}
                DescriptionIcon={showCheckmarkIcon ? CheckmarkIcon : undefined}
              />

              {error && (
                <div style={{ width: "100%" }}>
                  <ErrorBox data-severity="error">
                    {translate(`error_password_reset_code`, { context: error })}
                  </ErrorBox>
                </div>
              )}

              {showResendLink && (
                <div>
                  <nine-spacer s="sm"></nine-spacer>
                  <Link
                    className="color-c-80"
                    style={{ fontWeight: 400 }}
                    {...resendCodeLinkProps}
                  >
                    <Translate msg="resend_code" />
                  </Link>
                </div>
              )}

              <nine-content>
                <div className="center">
                  <nine-spacer s="lg"></nine-spacer>
                  <Button type="submit" style={{ margin: "auto" }}>
                    <Translate msg="verify" />
                  </Button>
                </div>
              </nine-content>
            </AutoForm>
          </div>
        </Loader>
      )}
    </Frame>
  );
};

export default ResetPasswordForm;
