import {
  AutoForm,
  AutoFormCheckboxGroupField,
  AutoFormInputField,
  Button,
  Checkbox
} from "@9amhealth/shared";
import type { FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import type FunnelKey from "src/constants/funnelKey";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import type Path from "src/constants/path";
import reportErrorSentry from "src/lib/reportErrorSentry";
import translate from "src/lib/translate";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { TrackEvent, TrackType } from "src/state/Track/TrackCubit";
import AuthenticationBloc from "src/state/UserCubit/AuthenticationBloc";
import UserCubit from "src/state/UserCubit/UserCubit";
import useUserAuthStage from "src/state/hooks/useUserAuthStage";
import { useBloc } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import SignupWrapper from "src/ui/components/SignupWrapper/SignupWrapper";
import Track from "src/ui/components/Track/Track";
import Translate from "src/ui/components/Translate/Translate";
import { z } from "zod";
import Link from "../Link/Link";

export interface Props {
  onSuccess?: () => unknown;
  loginLink: Path | (() => unknown);
  title?: TranslationKey;
  description?: TranslationKey;
  buttonLabel?: TranslationKey;
  scopeKey?: FunnelKey;
  noFrame?: boolean;
  limitWidth?: boolean;
}

const RegisterFormSchema = z.object({
  email: z.string().email(translate("invalid_email")),
  password: z.string().min(12, ""),
  consentSet1: z.array(z.literal("consentSet1"), {
    message: translate("required")
  }),
  consentSet2: z.array(z.literal("consentSet2"), {
    message: translate("required")
  })
});

const FormLayout = styled.div`
  display: grid;
  gap: 1rem;
  text-align: left;
  width: min(100%, ${APP_CONTENT_WIDTH_WITHOUT_PADDING}px);
  margin: 0 auto;

  .data-field-error {
    padding-bottom: 0;
  }
`;

const ButtonPosition = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
`;

const RegisterForm: FC<Props> = ({
  onSuccess,
  loginLink,
  noFrame = false,
  ...props
}) => {
  const authStage = useUserAuthStage();
  const [user] = useBloc(UserCubit, { subscribe: false });
  const [, { register }] = useBloc(AuthenticationBloc);
  const prefillEmail = user.userData?.email;

  const handleSubmit = (values: {
    email: string;
    password: string;
    consentSet1: "consentSet1"[];
    consentSet2: "consentSet2"[];
  }) => {
    register(
      {
        email: values.email,
        password: values.password
      },
      props.scopeKey
    )
      .then(() => {
        onSuccess?.();
      })
      .catch((error: unknown) => {
        reportErrorSentry(error);
      });
  };

  const { title, description } = props;

  const Frame = noFrame ? "div" : SignupWrapper;

  const loginLinkProps = {
    to: typeof loginLink === "function" ? "#" : loginLink,
    onClick: typeof loginLink === "function" ? loginLink : undefined
  };

  const trackData = props.scopeKey
    ? {
        "Funnel Key": props.scopeKey
      }
    : {};

  return (
    <Frame loadingKey={LoadingKey.register} hideBottomNavbar>
      {authStage !== "fullAuth" && (
        <Track
          event={TrackEvent.registration}
          type={TrackType.start}
          data={trackData}
          backend={true}
        />
      )}

      <nine-heading
        style={{
          "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
        }}
      >
        <h3 className="as-h4-large">
          {title ? (
            <Translate msg={title} />
          ) : (
            <Translate msg="create_account_title" />
          )}
        </h3>
        {description && (
          <>
            <nine-spacer s="md"></nine-spacer>
            <p className="m0 color-c-80">
              <Translate msg={description} />
            </p>
          </>
        )}
      </nine-heading>

      <nine-spacer s="xl"></nine-spacer>
      <AutoForm
        schema={RegisterFormSchema}
        onSubmit={(values) => handleSubmit(values)}
        initialValue={{ email: prefillEmail }}
      >
        <FormLayout>
          <AutoFormInputField
            name="email"
            type="email"
            required={true}
            isDisabled={Boolean(prefillEmail)}
            label={translate("emailAddress")}
          />
          <AutoFormInputField
            name="password"
            label={translate("password")}
            isPasswordToggleEnabled
            type="password"
            required={true}
            description={<Translate msg="password.requirements" />}
          />
          <div className="space"></div>

          <AutoFormCheckboxGroupField name="consentSet1">
            <Checkbox value={"consentSet1"}>
              <div>
                <Translate msg="registerConsent.set1" />
              </div>
            </Checkbox>
          </AutoFormCheckboxGroupField>
          <AutoFormCheckboxGroupField name="consentSet2">
            <Checkbox value={"consentSet2"}>
              <div>
                <Translate msg="registerConsent.set2" />
              </div>
            </Checkbox>
          </AutoFormCheckboxGroupField>
          <ButtonPosition>
            <Button type="submit">
              <Translate msg="confirm" />
            </Button>
          </ButtonPosition>
          <nine-content>
            <div className="center">
              <nine-spacer s="sm"></nine-spacer>
              <p className="m0 color-c-80">
                <Translate msg="login.alternative.question" />
              </p>
              <nine-spacer s="xxxs"></nine-spacer>
              <nine-spacer s="xxxs"></nine-spacer>
              <Link className="color-c-80" {...loginLinkProps}>
                <Translate msg="login.alternative.link" />
              </Link>
            </div>
          </nine-content>
        </FormLayout>
      </AutoForm>
    </Frame>
  );
};

export default RegisterForm;
